import createPlugin from '@@/create-plugin';
import { request, SearchResult, useActions, useAsyncEffect, useBook, useFile } from 'topwrite';

export default createPlugin({
    activate(name) {
        if (name === 'reader') {
            const { setHandler } = useActions('search');
            const file = useFile();
            const { summary } = useBook();

            useAsyncEffect(async () => {
                const lunr = (await import('lunr')).default;
                (await import('lunr-languages/lunr.stemmer.support')).default(lunr);
                (await import('lunr-languages/lunr.zh')).default(lunr);

                try {
                    const { data } = await request(file.relative('search_index.json'));

                    const idx = lunr.Index.load(data);

                    setHandler((keyword) => {
                        const results = idx.search(keyword);

                        return results.map((result) => {
                            const article = summary.getArticle(article => article.ref === result.ref);
                            if (article) {
                                return {
                                    title: article.title,
                                    path: article.path!
                                };
                            }
                        }).filter<SearchResult>((result): result is SearchResult => {
                            return !!result;
                        });
                    });
                } catch (e) {
                    console.error(e);
                }
            }, []);
        }
    }
});
